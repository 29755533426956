exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-sm-js": () => import("./../../../src/pages/about-sm.js" /* webpackChunkName: "component---src-pages-about-sm-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-is-it-sm-js": () => import("./../../../src/pages/is-it-sm.js" /* webpackChunkName: "component---src-pages-is-it-sm-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-test-for-kit-d-816-v-js": () => import("./../../../src/pages/test-for-kit-d816v.js" /* webpackChunkName: "component---src-pages-test-for-kit-d-816-v-js" */)
}

